.header.MuiAppBar-root {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    z-index: 1000;

    .logo-image {
        height: 58px;
        padding: 4px;
    }

    .toolbar-contents {
        align-items: center;
        display: flex;
        justify-content: center;
        width: 100%;
    }
}